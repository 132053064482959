import React from 'react';
import Page from './PageTemplate';
import './Events.scss';

class Events extends React.Component {
    render(props) {
        return (
            <Page title="Past and Upcoming Events">
                <p>You can register for A11yPHL events on our <a href="http://meetup.com/a11yphl">Meetup page</a></p>
                <Event title="Disability Justice for Technologists" date="9/18/2019">
                    <p>When you think of accessibility, do you immediately consider standards and requirements? Many technologists do. At this meetup, UX researcher and disability justice scholar Embry Wood Owen will present a different perspective: an exciting, radical history of activism. She will share stories from modern disability history and what we can learn from them to apply in our work in tech. You will leave with a firmer foundation for your accessibility work.</p>
                    <h5>About our speaker:</h5>
                    <p>Embry Wood Owen is a researcher, writer and advocate who advances access for disabled people in public space. She writes at <a href="https://www.embrywoodowen.com">www.embrywoodowen.com</a> and advises technologists, urban planners and others across Philadelphia. Her vision for access is grounded in her lived experience with disability and her five years of experience working in technology. She currently works as a UX researcher at P’unk Avenue.</p>
                </Event>
                <Event title="Web Accessibility Testing and Development" date="7/18/2019">
                    <p>Are you interested in getting your feet wet with manual accessibility testing? How about best practices in accessible website and web app development? Wait no longer—you'll get to learn about all these things from local accessibility professionals. Izzy Gong (MOD), Sonika Hait (Comcast), and Alex Umstead (Level Access and Comcast) will give YOU the scoop and best practices on testing and development for websites, web apps, and mobile webviews.</p>
                    <h5>About our speakers:</h5>
                    <ul>
                        <li>Alex Umstead has worked for Level Access (formerly SSB BART Group) since July 2016, and joined the Comcast Accessibility Team in late 2017. Alex enjoys creating inclusive digital experiences that work smoothly many different assistive technology and browser combinations. With a background in disability studies, Alex is passionate about bringing a disability justice ethos into accessibility work. He is the co-lead of A11yPHL.</li>
                        <li>Sonika Hait has been a web developer with the Comcast Accessibility Team since 2017. Previously, she worked for Farm Credit Canada through Tata Consultancy Services in India and Uruguay. At Comcast she has supported Xfinity Home and xFi engineering teams, where she's built accessible desktop and mobile webview experiences. Focusing on accessibility has inspired her to learn new techniques in building accessible websites.</li>
                        <li>Izzy Gong is a Full Stack Developer at MOD. Upon graduating from the University of Pennsylvania in May 2016, she spent the summer at a coding bootcamp before beginning her dev career at MOD. Around the agency, she is known for her passion for accessibility where she ruthlessly advocates for accessible design and development. Outside of work, she plays for a competitive quidditch team and is an experienced sports photographer.</li>
                    </ul>
                </Event>
            </Page>
        )
    }
}

class Event extends React.Component {
    render(props) {
        return (
            <section className="event">
                <hr aria-hidden="true" />
                <h4>{this.props.title + (this.props.date ? " (" + String(new Date(this.props.date).toDateString()) + ")" : "")}</h4>
                {this.props.children}
            </section>
        );
    }
}

export default Events;
