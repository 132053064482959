import React from 'react';
import Page from './PageTemplate';

class FormSubmissionPage extends React.Component {
    render() {
        return (
            <Page title="Form submitted!">
                <p>You just submitted a test form. Yaaaaaaaay.</p>
            </Page>
        );
    }
}

export default FormSubmissionPage;