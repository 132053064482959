import React from 'react';
import Page from './PageTemplate';
import { NativeCheckbox, AriaCheckbox } from '../components/checkbox';
import {CheckboxSet, AriaCheckboxSet } from '../components/checkboxSet';
import Autocomplete from '../components/autocomplete';
import {Entries, Macs} from '../data/autocomplete-data';

//old code I'd like to keep for now, but am removing from production because it's too dang wonky. Automatically pushes all exports in JS files in /components to the componnts page
// import AllComponents from '../routing/ComponentRouter';
/* {Object.keys(AllComponents).map((page) => {
    const currPage = AllComponents[page];
    const pageName = currPage.name;
    const pageToInsert = React.createElement(currPage);
    console.log(pageToInsert);
    // All Javascript files inside Components will automatically display.
    //Each component must have a "nickname" prop, which we'll use to create a heading for it.
    //Probably not the most "react-y" solution, but gets around issues with the class/function name being minified when deployed.
    //If no nickname prop is present, there'll be no heading for that component, but there's no other downside. 
    return (
        <Page key={pageName} subpage title={pageToInsert.props.nickname}>
            {pageToInsert}
        </Page>
    );
})} */
class ComponentsPage extends React.Component {
    render() {
        return (
            <Page title="Components" subhead="This page will eventually act as a repository for holding accessible components.">
                <h2>Autocomplete (combobox)</h2>
                <Autocomplete id="combo01" data={Entries}/>
                <h2>Autocomplete (combobox) with lots of options, showing after typing 2 characters</h2>
                <Autocomplete id="comboMacs" data={Macs} label="Select your favorite Mac model:" chars="2" />
                <h2>Native checkbox</h2>
                <NativeCheckbox />
                <h2>ARIA checkbox</h2>
                <AriaCheckbox />
                <h2>Native checkbox group (fieldset)</h2>
                <CheckboxSet />
                <h2>ARIA checkbox group (fieldset)</h2>
                <AriaCheckboxSet />
            </Page>
        )
    }
}

export default ComponentsPage;