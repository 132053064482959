import React from 'react';
import Page from './PageTemplate';


class ContactPage extends React.Component {
    render(props) {
        return (
            <Page title="Contact">
                <h2>Social Media</h2>
                <ul>
                    <li><a href="https://www.meetup.com/a11yphl/">A11yPHL on Meetup</a></li>
                    <li><a href="https://twitter.com/a11yphl">@A11yPHL on Twitter</a></li>
                </ul>
            </Page>
        )
    }
}

export default ContactPage;