import React from 'react';
import { AriaCheckbox, NativeCheckbox } from './checkbox';
// import Checkbox from './checkbox';

const checkboxData = ["Windows", "macOS", "iOS", "Debian"];

class CheckboxSet extends React.Component {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        legend: "What are your favorite operating systems? (Check all that apply)",
        isAria: false,
        nickname: "Checkbox group",
        id: "checkboxGroup"
    }

    render() {
        return (
            <form name="test" noValidate>
                <fieldset>
                    <legend>{this.props.legend}</legend>
                    {checkboxData.map((item, idx) => {
                        return (
                            <div key={item + "_key"}>
                                {this.props.isAria ? <AriaCheckbox label={item} id={this.props.id + "_ARIA_" + idx} /> : <NativeCheckbox label={item} id={this.props.id + "_" + idx} />}
                                </div>
                        )
                    })}
                </fieldset>
            </form>
        )
    }
}

function AriaCheckboxSet() {
    return(<CheckboxSet isAria="true" />)
}

export {CheckboxSet, AriaCheckboxSet};