import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './checkbox.scss';

class NativeCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.updateCheckedState = this.updateCheckedState.bind(this);
        this.state = {
            checked: this.props.checked
        }
    }
    static defaultProps = {
        label: "Accessibility is awesome",
        nickname: "Checkbox (Native)",
        id: "checkbox_native"
    }

    updateCheckedState(event) {
        this.setState({
            checked: event.target.checked
        })
    }

    render() {
        return (
            <div className="checkbox-container">
                <div className="checkbox-fake-container">
                    <input type="checkbox" id={this.props.id} className="checkbox-hidden" checked={this.props.checked ? true : null} onChange={this.updateCheckedState} />
                    <div className={"checkbox noclick" + (this.state.checked ? " checked" : "")}><FontAwesomeIcon icon={faCheck} /> </div>
                </div>
                <label htmlFor={this.props.id} className="checkbox-label">
                    {this.props.label}
                </label>
            </div>
        )
    }
}

class AriaCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked
        }
        this.handleClick = this.handleClick.bind(this);
        this.focusAndClick = this.focusAndClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.checkboxRef = React.createRef();
        this.displayName = "Checkbox";
    }
    //nicename prop is needed because the actual component name gets minified when pushing the code
    static defaultProps = {
        label: "Accessibility is awesome",
        nickname: "Checkbox (ARIA)",
        id: "checkbox_ARIA"
    }

    handleClick() {
        this.setState({
            checked: !this.state.checked
        });
    }

    focusAndClick() {
        this.checkboxRef.current.focus();
        this.handleClick();
    }

    handleKeyDown(e) {
        if (e.key === " " || e.key === "Enter") {
            //prevent page scroll if pressing space
            e.preventDefault();
        }
    }

    //click the checkbox on keyup to simulate "real" checkbox behavior and prevent repeated checking when holding space
    handleKeyUp(e) {
        if (e.key === " ") {
            e.preventDefault();
            this.setState({
                checked: !this.state.checked
            });
        }
    }

    // component must be wrapped in a Page tag, otherwise it won't be possible to add a heading for it due to everything getting minified when deploying
    // I really hate doing this, but am not sure of any other way to achieve this result, since I can't pass props from children to parents.
    render() {
        return (
            <div className="checkbox-container" onClick={this.focusAndClick}>
                <div ref={this.checkboxRef} id={this.props.id} className={"checkbox" + (this.state.checked ? " checked" : "")} tabIndex="0" role="checkbox" onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} aria-checked={this.state.checked} aria-label={this.props.label}><FontAwesomeIcon icon={faCheck} /></div><div className="checkbox-label noclick">{this.props.label}</div>
            </div>
        )
    }
}

export { AriaCheckbox, NativeCheckbox };