window.generateDateGrid = function(date) {
    date = date ? date : new Date();
    const num_weekdays = 7;
    const num_rows = 6;
    let firstWeekdayIndex = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    let lastDayInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    console.log("last", lastDayInMonth);
    var gridArray = [];
    //first day of month
    var indexDate = 1;
    // go thru each row
    // push null or number
    //increment number
        for(let i = 0; i < num_rows; i++) {
            let row = [];
            for(let a = 0; a < num_weekdays; a++) {
                if(indexDate <= lastDayInMonth) {
                    //first row only, filter out days prior to start day
                   if(i === 0) {
                       if(a < firstWeekdayIndex) {
                        row.push(null)
                       } else {
                        row.push(indexDate);
                        indexDate++;
                       }
                   } else {
                    row.push(indexDate);
                    indexDate++;
                   }
                } else {
                    row.push(null);
                }
            }
            if(row.join("").length > 0) {
                //only push non-empty rows into the grid
                gridArray.push(row);
            }
            
        }
 return gridArray;   
}

