import React from 'react';
import { Route, Switch, NavLink } from "react-router-dom";
import HomePage from '../pages/Home';
import ContactPage from '../pages/Contact';
import ComponentsPage from '../pages/Components';
import ExperimentsPage from '../pages/Experiments';
import FormSubmission from '../pages/FormSubmission';
import { default as EventsPage } from '../pages/Events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars } from '@fortawesome/free-solid-svg-icons';


const allPages = [
    {
        name: "Home",
        icon: faHome,
        path: "/",
        module: HomePage
    },
    {
        name: "Events",
        path: "/events/",
        module: EventsPage
    },
    {
        name: "Contact",
        path: "/contact/",
        module: ContactPage
    },
    {
        name: "Components",
        path: "/components/",
        module: ComponentsPage
    },
    {
        name: "Submission",
        path: "/submission/",
        nolink: true,
        module: FormSubmission
    },
    {
        name: "Experiments",
        path: "/experiments/",
        nolink: true,
        module: ExperimentsPage
    }

];


class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded
        }
        this.expandCollapse = this.expandCollapse.bind(this);
    }

    expandCollapse(collapseOnly) {
        if (collapseOnly !== true) {
            this.setState({
                expanded: this.state.expanded === true ? false : true
            });
        } else {
            this.setState({
                expanded: false
            });
        }
    }

    render() {
        return (
            <nav className="nav-container">
                <button className="nav-toggle" aria-expanded={this.state.expanded} onClick={this.expandCollapse} aria-label="Main navigation"><FontAwesomeIcon icon={faBars} /></button>
                <ul className="nav-ul">
                    {allPages.map((page) => {
                        return (
                            page.nolink !== true ? <li className="nav-item" key={page.path + "_listitem"}>
                                <NavLink onClick={() => { this.expandCollapse(true) }} to={page.path} exact activeClassName="active" aria-label={page.icon ? page.name : null}>{page.icon ? <FontAwesomeIcon icon={page.icon} /> : page.name}</NavLink>
                            </li> : ""
                        )})
                    }
                </ul>
            </nav>
        )
    }
}

class MainRoute extends React.Component {
    render() {
        return (
            <Switch>
                {allPages.map((page) => {
                    return (<Route key={page.path + "_route"} path={page.path} exact component={page.module} />);
                })}
            </Switch>
        )
    }
}


export { Nav, MainRoute };