import React from 'react';
import Page from './PageTemplate';
import HeroBanner from '../../src/assets/img/sky.jpg';

//add each page here, at least for now

class HomePage extends React.Component {
    render() {
        return (
            <Page title="A11yPHL - Philly's own accessibility meetup" heading="Welcome to A11yPHL!" heroimg={HeroBanner} isHome="true" hero>
                <h2>Who we are</h2>
                <p>A11yPHL is the revitalized version of what used to be called the Philly Accessibility Forum.</p>
                <p>We currently hold lightning talks and other events centered on technology accessibility and/or disability rights issues, every 2-3 months, in various locations across Philadelphia.</p>
            </Page>
        )
    }
}

export default HomePage;