import React from 'react';
import './calendar.css';
import './calendar-test-logic.js';

//TODO: Fix focus behavior so that you can have multiple calendars without arrow handling setting focus into the wrong one

class Cell extends React.Component {
    constructor() {
        super();
        this.focusTgt = React.createRef();
    }

    componentDidMount() {
        if(this.focusTgt.current) {
            this.focusTgt.current.focus();
        }
    }

    render() {
        //might want to use setState instead?
        //setting these here instead of in constructor because arrows don't work otherwise...
        this.selectedDateObj = new Date(this.props.selectedDate);
        this.convertedSelectedDateObj = String(this.selectedDateObj.getFullYear() + "," + this.selectedDateObj.getMonth() + "," + this.selectedDateObj.getDate());
        this.convertedThisDateObj = String(this.props.thisDate.getFullYear() + "," + this.props.thisDate.getMonth() + "," + this.props.thisDate.getDate());
        return (
            // this.convertedSelectedDateObj === this.convertedThisDateObj
            <div ref={this.convertedSelectedDateObj === this.convertedThisDateObj && !this.props.isHeader && this.props.focusDate ? this.focusTgt : null} role={(this.props.children && !this.props.isHeader) ? "button" : null}
                aria-label={(this.props.children && !this.props.isHeader) ? this.props.niceDayName : null}
                tabIndex={this.props.isHeader ? null : (this.convertedSelectedDateObj === this.convertedThisDateObj ? "0" : "-1")}
                className={"cell" + (this.props.isToday ? " today" : "") + (this.convertedSelectedDateObj === this.convertedThisDateObj && !this.props.isHeader ? " selected" : "") + (this.props.children ? "" : " hide")} > {this.props.isToday ? <span className="todaycircle">{this.props.children}</span> : this.props.children}</div>
        )
    }
}

class Row extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            todayInRow: (this.props.month === new Date().getMonth()) && (this.props.year === new Date().getFullYear())
        }
    }
    render() {
        return (
            <div className={"row" + (this.props.isHeader ? " header" : "")}>
                {this.props.cells.map((dayValue, idx) => {
                    return (<Cell focusDate={this.props.focusDate} thisDate={new Date(this.props.year, this.props.month, dayValue)} selectedDate={this.props.selectedDate} year={this.props.year} month={this.props.month} isHeader={this.props.isHeader} key={dayValue + "_" + idx} niceDayName={!this.props.isHeader ? (((this.state.todayInRow && dayValue === new Date().getDate()) ? "Today, " : "") + this.props.weekdays[idx] + ", " + this.props.months[this.props.month] + " " + dayValue + ", " + this.props.year) : null} isToday={this.state.todayInRow && (dayValue === new Date().getDate())}>{dayValue}</Cell>)
                })}
            </div>
        )
    }
}

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.date = this.props.date ? new Date(this.props.date) : new Date();
        this.rowCount = [0, 1, 2, 3, 4, 5];
        this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        //Start incrementing from 1 when generating an array of dates
        this.shortWeekdays = this.weekdays.map(dayName => { return dayName.slice(0, 3) });


        //weekday value 0 - 6 Sun - Sat
        // this.firstWeekdayValue = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), 1).getDay(); // https://stackoverflow.com/questions/1924815/how-to-get-last-day-of-the-month/1924846#1924846
        this.arrayOfDates = [];
        this.createWeeks = this.createWeeks.bind(this);
        this.handleKeys = this.handleKeys.bind(this);
        this.incrementStartDate = this.incrementStartDate.bind(this);
        this.focusTheDate = this.focusTheDate.bind(this);
        this.state = {
            selectedDate: new Date(this.date),
            grid: this.createWeeks(new Date(this.date))
        }
    }

    componentDidMount() {
        //do not set focus on initial calendar render
        this.setState({
            grid: this.createWeeks(this.state.selectedDate),
            focusDate: false
        });
    }

    handleKeys(e) {
        let newDate;
        switch (e.key) {
            case "ArrowUp":
                //last week
                e.preventDefault();
                newDate = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), parseInt(this.state.selectedDate.getDate() - 7));
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "ArrowDown":
                //next week
                e.preventDefault();
                newDate = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), parseInt(this.state.selectedDate.getDate() + 7));
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                console.log(e.target);
                break;
            case "ArrowLeft":
                //yesterday
                e.preventDefault();
                newDate = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), parseInt(this.state.selectedDate.getDate() - 1));
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "ArrowRight":
                //tomorrow
                e.preventDefault();
                newDate = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), parseInt(this.state.selectedDate.getDate() + 1));
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "PageUp":
                //prev month
                e.preventDefault();
                let noDayInPrevMonth = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() - 1, this.state.selectedDate.getDate()).getMonth() === this.state.selectedDate.getMonth();
                newDate = noDayInPrevMonth ? new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), 0) : new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() - 1, this.state.selectedDate.getDate());
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "PageDown":
                //next month
                e.preventDefault();
                let noDayInNextMonth = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() + 1, this.state.selectedDate.getDate()).getMonth() === this.state.selectedDate.getMonth() + 2;
                newDate = noDayInNextMonth ? new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() + 2, 0) : new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth() + 1, this.state.selectedDate.getDate());
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "Home":
                //prev year
                e.preventDefault();
                let leapDayPrevYear = new Date(this.state.selectedDate.getFullYear() - 1, this.state.selectedDate.getMonth(), this.state.selectedDate.getDate()).getMonth() !== new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), this.state.selectedDate.getDate()).getMonth();
                newDate = leapDayPrevYear ? new Date(this.state.selectedDate.getFullYear() - 1, this.state.selectedDate.getMonth() + 1, 0) : new Date(this.state.selectedDate.getFullYear() - 1, this.state.selectedDate.getMonth(), this.state.selectedDate.getDate());
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            case "End":
                //next year
                e.preventDefault();
                let leapDayNextYear = new Date(this.state.selectedDate.getFullYear() + 1, this.state.selectedDate.getMonth(), this.state.selectedDate.getDate()).getMonth() !== new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), this.state.selectedDate.getDate()).getMonth();
                newDate = leapDayNextYear ? new Date(this.state.selectedDate.getFullYear() + 1, this.state.selectedDate.getMonth() + 1, 0) : new Date(this.state.selectedDate.getFullYear() + 1, this.state.selectedDate.getMonth(), this.state.selectedDate.getDate());
                this.setState({
                    selectedDate: newDate,
                    grid: this.createWeeks(newDate),
                    focusDate: true
                });
                break;
            default:
                break;
        }

    }

    
    focusTheDate(toFocus) {
        //used for setting focus on arrow key press
        toFocus.focus();
    }

    incrementStartDate() {
        this.setState((state) => {
            return { startDate: state.startDate + 1 }
        });
    }
    createWeeks(date) {
        date = date ? date : new Date();
        const num_weekdays = 7;
        const num_rows = 6;
        let firstWeekdayIndex = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        let lastDayInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        var gridArray = [];
        //first day of month
        var indexDate = 1;
        // go thru each row
        // push null or number
        //increment number
        for (let i = 0; i < num_rows; i++) {
            let row = [];
            for (let a = 0; a < num_weekdays; a++) {
                if (indexDate <= lastDayInMonth) {
                    //first row only, filter out days prior to start day
                    if (i === 0) {
                        if (a < firstWeekdayIndex) {
                            row.push(null)
                        } else {
                            row.push(indexDate);
                            indexDate++;
                        }
                    } else {
                        row.push(indexDate);
                        indexDate++;
                    }
                } else {
                    row.push(null);
                }
            }
            if (row.join("").length > 0) {
                //only push non-empty rows into the grid
                gridArray.push(row);
            }
        }
        return gridArray;
    }



    render() {
        return (
            <section aria-label={this.months[this.state.selectedDate.getMonth()] + " " + this.state.selectedDate.getFullYear()} className="calendar">
                <div className="calendarheading" role={this.props.hasHeading ? "heading" : null} aria-level={this.props.hasHeading ? ((this.props.headingLevel && !isNaN(this.props.headingLevel) && this.props.headingLevel > 0 && this.props.headingLevel <= 6) ? parseInt(this.props.headingLevel) : 2) : null}>{this.months[this.state.selectedDate.getMonth()] + " " + this.state.selectedDate.getFullYear()}</div>
                <div role="application" className="calendargrid" onKeyDown={this.handleKeys}>
                    <Row cells={this.shortWeekdays} isHeader={true} />
                    {this.state.grid.map((row, idx) => {
                        // need to change this eventually so we can have multiple months cross a single row
                        return (<Row key={row + "_item" + idx} focusDate={this.state.focusDate} selectedDate={this.state.selectedDate} cells={this.state.grid[idx]} year={this.state.selectedDate.getFullYear()} month={this.state.selectedDate.getMonth()} weekdays={this.weekdays} months={this.months} />)
                    })}
                </div>
            </section>
        );
    }
}

export default Calendar;
