import React from 'react'
import './Hero.scss';

class Hero extends React.Component {
    constructor(props) {
        super(props)
        this.focusTgt = React.createRef();
    }
    componentDidMount() {
        this.focusTgt.current.focus();
        console.log(this.props.heroimg);
    }
    render() {
        return (
            <div className="hero" style={{backgroundImage:"url("+this.props.heroimg+")"}}>
                <h1 tabIndex="-1" ref={this.focusTgt}>{this.props.heading ? this.props.heading : this.props.title}</h1>
            </div>
        )
    }
}

export default Hero;