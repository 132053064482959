//es-disable-next-line
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Nav, MainRoute } from './routing/PageRouter';

class App extends React.Component {
  constructor(props) {
    super(props);
    //https://stackoverflow.com/questions/49605527/cannot-call-this-setstate-in-addeventlistener-function
    this.windowEvents = this.windowEvents.bind(this);
    this.state = {
      kbd: false
    }
  }
  componentDidMount() {
    window.pageAlreadyLoaded = true;
    this.windowEvents();
  }
  windowEvents() {
    window.addEventListener("mousemove", () => {
      this.setState({
        kbd: false
      })
    });
    window.addEventListener("keydown", () => {
      this.setState({
        kbd: true
      })
    });
  }

  //skip link will use vanilla JS for now, until I learn how to pass refs up from child elements.
  //the page template itself focuses on the h1 using a ref and componentDidMount
  render() {
    return (
      <div className={"App"+(this.state.kbd === true ? " show-focus" : "")}>
        <a href="#" className="sronly skip" onClick={() => { document.querySelector("h1").tabIndex = "-1"; document.querySelector("h1").focus(); }}>Skip to main content</a>
        <Router>
          <Nav expanded="false" />
          <MainRoute />
        </Router>
      </div>
    );
  }
}

export default App;
