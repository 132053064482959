import React from 'react';
import './autocomplete.scss';

class Listbox extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        console.log(this);
        console.log(e.target);
    }

    render() {
        const allItems = this.props.data.map((entry, idx) => {
            const thisId = this.props.id + "_option_" + idx;
            return (
                <ListItem key={entry + "_" + idx} id={thisId} ariaSelected={this.props.selectedItem === thisId} text={entry} />
            )
        });
        return (
            <ul role="listbox" id={this.props.id} className={"listbox" + (allItems.length > 5 ? " max" : "")} aria-labelledby={this.props.id ? (this.props.id + "_label") : null} onClick={this.handleClick}>
                {allItems}
            </ul>
        )
    }
}

class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li role="option" id={this.props.id} aria-selected={this.props.ariaSelected}>{this.props.text}</li>
        );
    }
}

//todo: manage selected state completely from the listbox, instead of partly from the listbox and partly from the combobox
class Autocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.filterResults = this.filterResults.bind(this);
        this.container = React.createRef();
        this.id = this.props.id + "_combo";
        this.listbox = React.createRef()
        this.state = {
            filtered: [],
            selectedItem: this.id
        }
    }

    filterResults(e) {
        const inputValue = new RegExp(e.target.value, "ig");
        const matches = e.target.value.length > 0 ? this.props.data.filter((valToMatch) => {
            return valToMatch.match(inputValue);
        }) : [];
        if (e.target.value.length >= this.props.chars) {
            this.setState({
                filtered: matches,
                selectedItem: this.id
            });
        } else {
            this.setState({
                filtered: [],
                selectedItem: this.id
            });
        }
    }

    handleKeyDown(e) {
        const keys = {
            "down": "ArrowDown",
            "up": "ArrowUp",
            "enter": "Enter",
            "esc": "Escape"
        };

        const matches = [].slice.call(this.container.current.querySelectorAll("[role='option']"));
        const isLast = matches.indexOf(document.getElementById(this.state.selectedItem)) === matches.length - 1;

        switch (e.key) {
            case keys.down:
                if (this.listbox.current.props.data.length > 0) { //don't move cursor within field if matches are shown 
                    e.preventDefault()
                }
                if (!matches.indexOf(this.state.selectedItem)) {
                    this.setState({
                        selectedItem: matches[0].id
                    })
                } else if (matches.indexOf(this.state.selectedItem) && !isLast) {
                    this.setState({
                        selectedItem: matches[matches.indexOf(document.getElementById(this.state.selectedItem)) + 1].id
                    })
                }
                break;
            case keys.up:
                if (this.listbox.current.props.data.length > 0) { //don't move cursor within field if matches are shown 
                    e.preventDefault()
                }
                if (matches.indexOf(this.state.selectedItem)) {
                    if (matches.indexOf(document.getElementById(this.state.selectedItem)) > 0) {
                        this.setState({
                            selectedItem: matches[matches.indexOf(document.getElementById(this.state.selectedItem)) - 1].id
                        })
                    } else {
                        this.setState({
                            selectedItem: this.id
                        })
                    }
                }
                break;
            case keys.enter:
                if (matches.indexOf(document.getElementById(this.state.selectedItem)) >= 0) {
                    alert("You selected " + document.getElementById(this.state.selectedItem).innerText);
                    this.setState({
                        filtered: [],
                        selectedItem: this.id
                    });
                }
                break;
            case keys.esc:
                console.log("esc");
                break;
        }
    };

    render() {
        return (
            <div className="combobox-container" ref={this.container}>
                <label htmlFor={this.props.id + "_combobox"} id={this.props.id + "_label"} className="combobox-label">{this.props.label}</label>
                <div className="combobox-container-inner combobox">
                    <input autoComplete="off" onInput={this.filterResults} onKeyDown={this.handleKeyDown} id={this.props.id + "_combobox"} className="combobox" type="text" role="combobox" aria-controls={this.props.id + "_listbox"} aria-activedescendant={this.state.selectedItem} aria-autocomplete="list" />
                    <Listbox ref={this.listbox} id={this.props.id + "_listbox"} data={this.state.filtered} selectedItem={this.state.selectedItem} />
                </div>
            </div>
        );
    }
};

Autocomplete.defaultProps = {
    id: "combobox1",
    label: "Select an accessibility thing:",
    nickname: "Autocomplete",
    data: ["WCAG", "ARIA", "Screen reader", "JAWS", "NVDA", "VoiceOver", "Window-Eyes"],
    chars: 1
}

export default Autocomplete;