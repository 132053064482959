import React from 'react';
import Hero from './Hero';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.focusTgt = React.createRef();
    }
    componentDidMount() {
        this.props.isHome && this.props.isHome === "true" ? document.title = this.props.title : document.title = this.props.title + " - A11yPHL";
        if (window.pageAlreadyLoaded === true) {
            //probably the wrong way to do this since I'm using React and not vanilla JS
            //todo: find a way to grab data from arbitrary file and pass it to things that use this template
            /*will need to redo this with refs again if I need to set focus on subpage h2s instead of just to the h1.
            but doing the h1 thing for now because I need to get the Hero component to work.*/
            if(this.focusTgt.current) {
                this.focusTgt.current.focus();
            }
        }
    }
    //if has subpage prop, turn into a subpage that will be rendered on the main page.
    //the main page should NEVER have the subpage prop, as this will remove the h1, and will also mess up the styling
    render(props) {
        return (
            <div className="main-container">
                {this.props.heading ? <Hero heading={this.props.heading} heroimg={this.props.heroimg}/> : null}
                <div className={(this.props.subpage ? "subpage" : "main")}>
                    {this.props.subpage ? <h2 ref={this.focusTgt}>{this.props.heading ? this.props.heading : this.props.title}</h2> : (!this.props.hero ? <h1 tabIndex="-1" ref={this.focusTgt}>{this.props.heading ? this.props.heading : this.props.title}</h1> : null)}
                    {this.props.subhead ? <p>{this.props.subhead}</p> : null}
                    <div className={this.props.subpage ? null : "module"}>
                    {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default Page;