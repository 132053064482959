import React from 'react';
import Page from './PageTemplate';
import './Experiments.css';
import Calendar from '../components/calendar';

//todo: componenentize the function for rotating the cube when focusing on its links

class Scene extends React.Component {
    render() {
        return (
            <div className="scene">{this.props.children}</div>
        )
    }
}

class ListItem extends React.Component {
    constructor(props) {
        super(props)
        this.item = React.createRef()
    }
    render() {
        return (
            <li ref={this.item} className={this.props.className} style={this.props.style}>{this.props.children}</li>
        )
    }
}

const cubeData = [
    {
        name: "front",
        color: "rgba(0,0,0,.5)",
        degreesX: 0,
        degreesY: 0
    },
    {
        name: "back",
        color: "rgba(128,0,255,0.5)",
        degreesX: 0,
        degreesY: 90
    },
    {
        name: "right",
        color: "rgba(0,0,128,0.5)",
        degreesX: 0,
        degreesY: 180
    },
    {
        name: "left",
        color: "rgba(128,128,0,0.5)",
        degreesX: 0,
        degreesY: -90
    },
    {
        name: "top",
        color: "rgba(128,0,128,0.5)",
        degreesX: 90,
        degreesY: 0
    },
    {
        name: "bottom",
        color: "rgba(255,0,64,0.5)",
        degreesX: -90,
        degreesY: 0
    }
];
class Cube extends React.Component {
    constructor(props) {
        super(props)
        this.allRefs = [];
        this.props.data.forEach((side) => {
            this.allRefs.push(this[String(side.name) + "_ref"] = React.createRef());
        });
        this.updateLinkStyles = this.updateLinkStyles.bind(this);
        this.state = {
            expanded: false
        }
    }

    updateLinkStyles() {
        const styleTagContent = [];
        this.props.data.map((side) => { styleTagContent.push(`.${side.name} {transform: rotateY(${side.degreesY}deg) rotateX(${side.degreesX}deg) translateZ(6.25rem); background-color: ${side.color};} .${side.name} a:hover, .${side.name} a:focus { background: rgba(0,0,0,0.25);}`) });
        return styleTagContent.join("\n");
    }

    render() {
        return (
            <div>
                <style>{
                    `.cube {
                            position: relative;
                            padding: 0;
                            display: block;
                            width: 100%;
                            height: 100%;
                            transform: rotateX(${this.props.rotateX}deg) rotateY(${this.props.rotateY}deg);
                            transform-origin: center;
                            transform-style: preserve-3d;
                            transition: transform 200ms ease-in-out;
                        }
                        ${this.updateLinkStyles()}
                        @media (prefers-reduced-motion: reduce) {
                            .cube {
                                transition: none;
                            }
                            .side {
                                background: rgba(0,0,0,0.65) !important;
                            }
                        }`
                }
                </style>
                <div className="cubeContainer">
                    {/* I'm using aria-label here instead of just exposing inner text because some screen readers will read the two spans as separate stops. So yes, this is deliberate. */}
                    <button className="opener" aria-expanded={this.state.expanded} onClick={() => this.setState({ expanded: !this.state.expanded })} aria-label="Toggle menu"><span className="button-inner">Toggle</span><span className="button-inner">Menu</span></button>
                    <ul className="cube">
                        {this.props.data.map((side) => {
                            return (
                                <ListItem rotatex={side.degreesX} rotatey={side.degreesY} ref={this[String(side.name) + "_ref"]} className={"side" + " " + side.name} key={side.name} >
                                    <a href={`#${side.name}`} onFocus={(e) => { this.props.findRelatedSide(e, this.allRefs) }} onClick={(e) => { e.preventDefault(); document.getElementById(e.target.href.split("#")[1]).focus(); }}>{String(side.name)}</a>
                                </ListItem>)
                        })}
                    </ul>
                </div>
                {this.props.data.map((side, idx) => {
                    const upperCased = side.name.replace(side.name[0], side.name[0].toUpperCase())
                    return (
                        <div key={`${side.name}_${idx}_tgt`}>
                            <h3 id={side.name} tabIndex="-1">{upperCased}</h3>
                            <div>Target content for {side.name} side link element</div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

class ExperimentsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotateX: 0,
            rotateY: 45
        }
        this.xslider = React.createRef();
        this.yslider = React.createRef();
        this.cube = React.createRef();
        this.findRelatedSide = this.findRelatedSide.bind(this);
    }

    findRelatedSide(e, data) {
        const filtered = data.filter((ref) => {
            if (ref.current.item.current === e.target.closest("li")) {
                return ref;
            }
        })[0].current;
        //have to reverse the degrees so that the correct items get focused
        this.setState({
            rotateX: filtered.props.rotatex ? 0 - filtered.props.rotatex : 0,
            rotateY: filtered.props.rotatey ? 0 - filtered.props.rotatey : 0
        })
    }


    render() {
        return (
            <Page title="Experiments" subhead={<React.Fragment>Random experiments. <strong>Not a public page. Here there be dragonzes!</strong></React.Fragment>}>
                {/* <Calendar /> */}
                <Calendar date="2020/1/31"/>
                <h2>Navigation Menu Cube Jawn</h2>
                <div className="center-text">
                    <strong className="big bolder">Warning!!!</strong>
                    <div>This example contains many animations, as well as transparency. If you are sensitive to these, please turn on reduced motion in your operating system preferences.</div>
                    <div>Note that some flashing may still occur. Approach with caution.</div>
                </div>
                <h2>Instructions</h2>
                <ol>
                    <li>Open the navigation menu. A cube will appear.</li>
                    <li>Use the Tab key to move through the links in the cube.</li>
                </ol>
                <p>Credit for technique: <a href="https://3dtransforms.desandro.com/cube">Intro to 3D transforms: Cube</a></p>
                <label htmlFor="rotateX">Vertical rotation (degrees): </label>
                <input ref={this.xslider} type="number" id="rotateX" max="360" value={this.state.value} defaultValue={this.state.rotateX} onChange={(e) => { this.setState({ rotateX: e.target.value }); }} />
                <label htmlFor="rotateY">Horizontal rotation (degrees): </label>
                <input ref={this.yslider} type="number" id="rotateY" max="360" value={this.state.value} defaultValue={this.state.rotateY} onChange={(e) => { this.setState({ rotateY: e.target.value }) }} />
                <button type="button" onClick={() => { this.setState({ rotateX: 0, rotateY: 0 }); this.xslider.current.value = 0; this.yslider.current.value = 0; this.cube.current.allRefs[0].current.item.current.children[0].focus(); }}>Reset</button>
                <Scene>
                    <Cube ref={this.cube} data={cubeData} findRelatedSide={this.findRelatedSide} rotateX={this.state.rotateX} rotateY={this.state.rotateY} />
                </Scene>
            </Page>
        )
    }
}

export default ExperimentsPage;